<template>
  <div>
    <b-row>
      <b-col>
        <suppliers-card :item-key="itemKey" />
      </b-col>
      <b-col>
        <invoice-number-card :item-key="itemKey" />
      </b-col>
      <b-col>
        <StocksCard :item-key="itemKey" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <entry-amount-card :item-key="itemKey" />
      </b-col>
      <b-col>
        <price-card
          :item-key="itemKey"
          :is-required="false"
        />
      </b-col>

      <b-col>
        <warehouses-card :item-key="itemKey" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-alert
          v-if="stockData.max_amount && stockData.max_amount < dataItem[itemKey].entry_amount"
          show
          variant="warning"
        >
          <div class="alert-body text-center">
            <FeatherIcon icon="InfoIcon" /> Giriş Miktarı stok kartının girilen en yüksek tutar sınırını geçmektedir.

          </div>
        </b-alert>
      </b-col>
    </b-row>

  </div>
</template>
<script>
import {
  BRow, BCol, BAlert,
} from 'bootstrap-vue'
import EntryAmountCard from '@/views/Admin/Stocks/Elements/QuickAdd/entry_amount.vue'
import PriceCard from '@/views/Admin/Stocks/Elements/QuickAdd/priceCard.vue'
import InvoiceNumberCard from '@/views/Admin/Stocks/Elements/QuickAdd/invoiceCard.vue'
import SuppliersCard from '@/views/Admin/Stocks/Elements/QuickAdd/suppliersCard.vue'
import WarehousesCard from '@/views/Admin/Stocks/Elements/QuickAdd/wareHousesCard.vue'
import StocksCard from '@/views/Admin/Stocks/Elements/QuickAdd/StocksCard.vue'

export default {
  name: 'QuickStockLineForm',
  components: {
    BAlert,
    StocksCard,
    WarehousesCard,
    SuppliersCard,
    InvoiceNumberCard,
    PriceCard,
    EntryAmountCard,
    BRow,
    BCol,
  },
  props: {
    itemKey: {
      type: Number,
      required: true,
    },
  },
  computed: {
    dataItem() {
      return this.$store.getters['stockLines/dataItem']
    },
    stockData() {
      return this.$store.getters['stocks/dataItem']
    },
  },
}
</script>
